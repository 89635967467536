import { graphql } from "gatsby"
import React from "react"

import Img from "gatsby-image"
import { Row, Col } from 'react-bootstrap';
import Layout from "../layouts"
import Container from "../components/container"
import { rhythm } from "../utils/typography"
import constants from "../utils/constants"

const PageTemplate = ({ data }) => (
  <Layout>
    <div
      css={{
        background: constants.paleYellow,
        padding: rhythm(1.5),
        paddingTop: 0,
        marginBottom: rhythm(3),
      }}
    >
      <Container>
        <br />
        <h1>{data.page.title}</h1>
        <Col xs={12} md={8}>
        <Img 
          fluid={
            data.page.relationships.image.localFile.childImageSharp.fluid
          }
        />
        </Col>
        <div dangerouslySetInnerHTML={{ __html:data.page.body.value }} >
        </div>
      </Container>
    </div>
  </Layout>
)

export default PageTemplate

export const query = graphql`
  query($slug: String!) {
    page: nodeArticle(fields: { slug: { eq: $slug } }) {
      title
      body {
        value
      }
      id
      relationships {
        image: field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 900, maxHeight: 550) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`